<template>
	<div class="right">
		<!-- 百舸争流，奋楫者先 江苏省文交所影视版权交易中心 -->
		<!-- <div style="text-align: center">
      <div style="color: rgb(0, 118, 254)">
        {{info.title}}
      </div>
      <div class="font-first right-ctime">{{info.ctime}}</div>
    </div> -->
		<div v-html="info.content" class="right-content"></div>
		<div class="font-first right-ctime" style="float: right;margin-top:30px;">{{info.ctime}}</div>
	</div>
</template>

<script>
	export default {
		async created() {
			const {
				data
			} = await this.$api({
				c: "acticle",
				a: "acticle_detail",
				id: this.$route.query.id
			})
			//console.log(data)
			this.info = data
			var pic_content = data.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
			this.info.content = pic_content;
		},
		data() {
			return {
				info: {

				},
				//       Text: `       总体而言，越是知名的网络游戏，在我国的游戏玩家越是集中在北上广深以及成都、重庆等网络产业发达的城市，而随着二三线玩家的活跃，游戏P开始呈"下沉"趋势，覆盖地城和人群更广。在市场扩大的同时，侵权问题也逐渐暴露出来。知识产权发挥应有作用，维权眼得上、权益保得住，市场才能有长久的生命力。
				//         知名网络游戏《热血传奇》境内委托维权方中传悦众〔北京〉文化发展有限公司（简称*中传悦众公司”》的气氛突然紧张起来。        发现异常“各项比对结果指向侵权”
				//         在排查中显示异常的网络P地址。服务器上架设有传奇私服网站。
				//         收到公司专业团队的初步判定，中传悦众公司执行董事兼总经理秦智勇大脑飞速运转:一方面，加快建设知识产权强国是国家重大决策部署，党的十九大报告明确指出“倡导创新文化，强化知识产权创造、保护、运用"，加强知识产权保护工作顶层设计又是"十四五"规划重要任务之一，知识产权重要性不言而喻，对于文化创意产业尤为显著，作为文化央企，保护知识产权的使命在肩﹔另一方面，公司的中传新文创（P〉平台是《热血传奇》P在中国进行合法化合作的唯一窗口，保护知识产权所有权人合法权益的责任在肩。
				//         迅速反应“对侵权行为绝不姑息，维权刻不容缓”
				//         定位到IP地址位于湖南省衡阳市，中传悦众公司第一时间与湖南省衡阳市文化市场综合行政执法支队取得联系。
				//         维权大幕拉开，开局比较顺利。在中传悦众公司的支持下，执法支队利用大数据技术，克服了涉事点位处网络文化欠发达地区、可访问网站有限的阻碍，锁定了涉嫌侵权的网站。经查，该游戏没有网络游戏出版物号，涉嫌擅自上网出版运行的网络游戏，而通过对网络游戏《热血传奇》和侵权游戏的比对初步判定，游戏界面中的人物角色、道具装备等方面高度相似，涉嫌侵犯《热血传奇》著作权。执法人员通过持续不断调查发现，不需验证身份证便可正常注册登录，该游戏每天新开一个服务器，类似"硬伤"个个踩线，最重要的是游戏充值系统完备，意味着侵权者以此谋利。
				//         可就在证据越来越充分、线索越来越清晰之时，一个不小的障碍如大石般挡住了调查之路，受技术和职权范围所限，执法人员无法锁定当事人。调取不了关键证据，调查面临中断。
				//         打破僵局“提供芙键支撑。把准关键时机”
				//         了解到调查情况，中传悦众公司第一时间出具了著作权人相关权益权利证明和举报函。同时，该公司专员在散次现场调查后，再度前往衡阳协同办案，为执法部门锁定和固定证据提供了专业支持和便捷渠道。
				//         很快，案件受到相关部门的重视，并给予协助，涉案人员迅速被锁定，案件办理进程大大加快，法网正越收越紧。
				//         在谨慎的办案过程中，执法的关键时机来了。中传悦众专员勘测到准确信息"游戏马上要更新版本”，这意味着必须要在服务器变更前找到涉案人员。否则几个月来的调查取证努力都将付诸东流。
				//         能够在新型网络文化市场贫瘠、文化综合行政执法职能有限的条件下，成功查办游戏侵权案，湖南省衡阳巿文化市场综合行政执法支队负责人深有感触:“通过这次办案，我们切身体会到中传新文创〈P)）平台在固定证据、方便迅速找到著作权人发挥的作用。其在保护知识产衩服务于中小企业的同时，也服务于行政机关，职极履行央企社会责任。未来，这一平台在我们办理著作权案件过程中将会发挥越来越重要作用。"通过这起案件，衡阳网络执法专项联合办案机制取得了突破，积累了网络执法办案的经验。
				//         在案件的风暴眼中，《热血传奇》著作权人之一的株式会社传奇IP的权益得到有力保障，该公司负责人在了解全部案情后更是热烈点赞:“中传悦众公司作为我方委托的维权人，通过IP侵权监测系统搜索引擎的爬取功能来自主发现有关侵权线索并迅速反应，这充分体现了中国在知识产权保护方面的主动性。僵权问题能够如此迅速、妥盖地解决，让我们看到了中传新文创（(P〉平台的诚信度和行动力。正是有这样的全链条服务和高效度维权，我们才有意愿推动游戏在中国区域内的合法化经营，有信心与大多数中国游戏企业建立相生共荣的合作体。未来，我们将更坚定地与中传新文创（P〉平台携手，为游戏行业在IP价值最大化、以创新推动P开发以及整体游戏运营环境方面迈出新步伐。”
				//         “中国十分重视知识产权保护，我们拥有良好的法律环境，新冠·炎疫情防控成效显著，欢迎更多公司将知名游戏品牌P落户中国
				// 。“秦智勇说。
				//         来源:中国文化报记者王彬
				//         责编:李天娇
				// `,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.right {
		background: #ffffff;
		padding: 56px 34px 98px;
		width: 100%;

		.right-ctime {
			padding: 24px 0px 30px;
			// border-bottom: 1px solid #dfdfdf;
			margin-bottom: 33px;
		}

		.right-content {
			padding: 0 40px;
			white-space: pre-wrap;
			line-height: 25px;
			font-size: 14px;
		}
	}
</style>
